header {
    display: flex;
    justify-content: center;
    background-color: var(--app_main_background);
    padding: 0.75rem 0;
}

header nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 1400px;
    padding: 0 24px;
}

header nav.mobile {
    flex-direction: column;
}

header nav.desktop {
    flex-direction: row;
}

section.logo-apps {
    display: flex;
    flex-direction: row;
}

hr {
    color: var(--app_header_app_hover);
}

section.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}