.spinner-place {
    display: flex;
    align-items: center;
}

.spinner-place .spinner {
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
.loader {
    border: 6px solid var(--spinner_border);
    border-top: 6px solid var(--app_main_background);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}
