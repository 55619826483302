.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
}

#crypto .section {
    padding: 5px;
}

#crypto .current.section .icon {
    font-size: 0.9rem;
}

#crypto .amount.section .icon {
    font-size: 0.7rem;
}

#crypto .section .icon {
    margin-right: 5px;
}

#crypto .section .value {
    font-size: 0.7rem;
}

#crypto > div > span.section.change {
    font-size: 0.9rem;
}

#crypto > div > span.section.change > span {
    margin-left: 8px;
}

#crypto > div > span.section.change > span.value.posotive {
    color: green;
}

#crypto > div > span.section.change > span.value.negative {
    color: red;
}

#crypto .current:link,
#crypto .current:visited,
#crypto .amount:link,
#crypto .amount:visited {
    text-decoration: none;
}

#crypto .period > select {
    border: none;
}

@media screen and (min-width: 768px) {
    #crypto .current.section .icon {
        font-size: 1.1rem;
    }
    
    #crypto .amount.section .icon {
        font-size: 0.9rem;
    }
    
    #crypto .section .icon {
        margin-right: 10px;
    }
    
    #crypto .section .value {
        font-size: 0.9rem;
    }
}

