section.buttons {
    display: flex;
}

section.buttons a.button {
    border: 1px solid transparent;
    border-radius: 0.25rem;
    border-color: var(--app_header_button);

    padding: 0.375rem 0.75rem;
    margin: 0 0.5rem;

    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    color: var(--app_header_button);
    line-height: 1.5;
    font-size: 1rem;
    display: flex;
    white-space: nowrap;
}

section.buttons a.button:hover, 
section.buttons a.button:active {
    color: var(--app_body_color);
    background-color: var(--app_header_button);
    border-color: var(--app_header_button);
}

