#sub-group.sub-group-container {
    display: flex;
    flex-direction: column;
    align-items: start;
}

#sub-group.sub-group-container span.sub-group{
    border-radius: 5px;
    background-color: rgb(238, 240, 241);
    color: #3f697e;
    margin-left: 15px;
    margin-bottom: 5px;
    padding: 4px 7px;
    font-size: .9rem;
}