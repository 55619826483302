div.logo {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

div.logo img {
    width: 38px;
    height: 38px;
}

div.logo span.brand {
    color: var(--app_header_brand);
    font-size: 1.6rem;
    margin-left: 1rem;
}


