.widgets-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: var(--dialog_background_color);
}

.widget-container {
    width: 410px;
    margin: 5px 1px;
}

@media screen and (min-width: 600px) {
    .widget-container {
        width: 500px;
        margin: 5px;
    }
}

@media screen and (min-width: 768px) {
    .widget-container {
        width: 600px;
        margin: 10px;
    }
}

.widget-content {
    background-color: var(--widget_background_color);
}

