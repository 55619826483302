.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
}

.section {
    padding: 5px;
}

#current.section .value {
    margin: 0 10px;
}

#change.section {
    display: flex;
    font-size: 0.9rem;
}

#change.section .value {
    margin: 0 10px;
}

#change.section .value.posotive {
    color: red;
}

#change.section .value.negative {
    color: green;
}

#period > select {
    border: none;
}

#value {
    width: auto;
}

span.section.value > input[type=number] {
    font-size: 0.8rem;
    margin: 0;
    padding: 5px;
    width: 80px;
    border-radius: 5px;
}

span.section.value > button {
    font-size: 1rem;
    padding: 0 5px;
    margin: 0 10px;
    background-color: rgb(240, 240, 240);
}

.long-text {
    display: none;
}    

@media screen and (min-width: 768px) {
    .title {
        padding: 0 20px;
    }

    .long-text {
        display: block;
    }    
}
