html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: var(--app_body_color);
  font-weight: 400;
}

main {
  max-width: 1400px;
  margin: 0 auto;
}

textarea,
input,
button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  /* box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2); */
  background-color: white;
  line-height: 1.5;
  margin: 0;
}

textarea:hover,
input:hover,
button:hover {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Segoe UI", Arial, sans-serif;
  font-weight: 400;
  /*margin: 10px 0;
  color: var(--app_h_color);*/
}

a {
  color: var(--app_link_color);
  text-decoration: underline;
}

#root {
  height: 100%;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-button:not(.collapsed) {
  color: #3d718c;
  background-color: #ecf2f5;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

input {
  margin-top: 10px;
  border-radius: var(--form_input_border_radius);
  border: 1px solid var(--form_input_border_color);
}

button.primary {
  margin-top: 10px;
  color: var(--app_light_color);
  background-color: var(--app_main_background);
  border: var(--app_main_background);
  border-radius: var(--form_button_border_radius);
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.25rem;
}

button.primary:active,
button.primary:focus,
button.primary:hover
{
  color: var(--app_light_color);
  background-color: var(--app_hover_background);
  border: var(--app_hover_background);
}

:root {
  --app_body_color: #121212;
  --app_light_color: #fff;
  --app_h_color: color: #666;
  --app_main_background: #417690;
  --app_hover_background: #314d61;
  --app_header_brand: #ffc107;
  --app_header_button: #ffe484;
  --app_header_app_active: #def176;
  --app_header_app: rgba(255,255,255,0.85);
  --app_header_app_hover: #afc6d7;
  --dialog_background_color: #f5f5f5;
  --widget_background_color: #fff;
  --app_link_color: #447e9b;
  --form_input_border_color: #ced4da;
  --form_input_border_radius: 0;
  --form_button_border_radius: 0.3rem;
  --spinner_border: #f3f3f3;
  --temp-hot: #ffc5bf;
  --temp-zero: #f0f4c1;
  --temp-cold: #bfd9ff;
  --temp-hot-bord: #fd9e93;
  --temp-zero-bord: #ccee95;
  --temp-cold-bord: #a0c6ff;
}