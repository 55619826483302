.form-container {
    background-color: var(--dialog_background_color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}
  
form {
    width: 300px;
}